<template>
  <a-card :bordered="false">
    <div class="table-page-search-wrapper">
      <a-form layout="inline">
        <a-row :gutter="48">
          <a-col :md="5" :sm="15">
            <a-form-item label="流程定义key">
              <a-input placeholder="请输入" v-model="queryParam.key" allowClear />
            </a-form-item>
          </a-col>
          <a-col :md="8" :sm="24">
            <span class="table-page-search-submitButtons">
              <a-button type="primary" @click="getData">查询</a-button>
<!--              <a-button style="margin-left: 8px" @click="() => (queryParam = {})">重置</a-button>-->
              <a-button style="margin-left: 8px" @click="reset">重置</a-button>
            </span>
          </a-col>
        </a-row>
      </a-form>
    </div>
    <div class="table-operator">
      <a-button
        v-if="removeEnable"
        :disabled="selectedRowKeys.length === 0"
        type="danger"
        icon="delete"
        @click="delByIds(selectedRowKeys)"
        >删除</a-button
      >
      <a-upload
        v-if="addEnable"
        name="file"
        :action="uploadUrl"
        :headers="headers"
        :showUploadList="false"
        @change="uploadChange"
      >
        <a-button icon="upload">部署流程文件</a-button>
      </a-upload>
    </div>
    <!-- 数据表格 -->
    <a-table
      :loading="tableLoading"
      :row-selection="rowSelection"
      :rowKey="(record) => record.deploymentId"
      :columns="columns"
      :data-source="tableData"
      bordered
      :pagination="page"
      :rowClassName="rowClassName"
      @change="changeTable"
    >
      <span slot="name" slot-scope="text">
        <a-tooltip>
          <template slot="title"> {{ text }} </template> {{ text }}
        </a-tooltip>
      </span>
      <span slot="index" slot-scope="text, row, index">{{ index + 1 }}</span>
      <span slot="logo" slot-scope="item">
        <ZoomMedia style="height: 20px" :value="item"></ZoomMedia>
      </span>

      <span slot="resourceName" slot-scope="text, record">
        <a @click="handleModelXml(record)">{{ text }}</a>
      </span>
      <span slot="dgrmResourceName" slot-scope="text, record">
        <a @click="handleShowImage(record.deploymentId)">{{ text }}</a>
      </span>
      <span slot="action" slot-scope="text, record">
        <a-popconfirm
          placement="topRight"
          title="该操作会一并 激活/挂起 所有流程实例?"
          @confirm="handleState(record)"
          okText="是"
          cancelText="否"
          v-if="editEnabel"
        >
          <a-icon slot="icon" type="question-circle-o" style="color: red" />
          <a>{{ record.suspensionState === 1 ? '挂起' : '激活' }}</a>
        </a-popconfirm>
        <a-divider v-if="editEnabel" type="vertical" />
        <a v-if="editEnabel" @click="handleNode(record.id)">节点配置</a>
        <a-divider v-if="removeEnable" type="vertical" />
        <a v-if="removeEnable" @click="delByIds([record.deploymentId])">删除</a>
      </span>
    </a-table>

    <a-modal :visible="previewVisible" :width="900" :footer="null" @cancel="previewCancel">
      <img style="width: 100%" :src="previewImage" />
    </a-modal>
    <proc-def-node ref="modal" @ok="getData()" />
  </a-card>
</template>

<script>
import Vue from 'vue'
import { ACCESS_TOKEN } from '@/store/mutation-types'
import { STable } from '@/components'
import { getProfList, delProf, diagram, modelXml, processState, deployByFileURL } from '@/api/activiti'
import ProcDefNode from './ProcDefNode'
import { checkPermission } from '@/utils/permissions'

export default {
  name: 'ProfList',
  components: {
    STable,
    ProcDefNode,
  },
  data() {
    return {
      tableData: [],
      queryParam: {}, // 搜索条件
      scroll: { y: 600 },
      tableLoading: false, //表格loading
      page: {
        current: 1,
        pageSize: 10,
        total: 0,
        showSizeChanger: true,
        showTotal: () => `共${this.page.total}条`,
      },
      selectedRowKeys: [],
      selectedRows: [],

      visible: false,
      labelCol: {
        xs: { span: 24 },
        sm: { span: 5 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
      form: this.$form.createForm(this),
      mdl: {},
      permissions: [],
      // 高级搜索 展开/关闭
      advanced: false,
      // 表头
      columns: [
        {
          title: '#',
          scopedSlots: { customRender: 'index' },
        },
        {
          title: '名称',
          dataIndex: 'name',
        },
        {
          title: '流程定义key',
          dataIndex: 'key',
        },
        {
          title: '版本',
          dataIndex: 'version',
        },
        {
          title: '部署ID',
          dataIndex: 'deploymentId',
        },
        {
          title: '资源名称',
          dataIndex: 'resourceName',
          scopedSlots: { customRender: 'resourceName' },
        },
        {
          title: '流程图名称',
          dataIndex: 'dgrmResourceName',
          scopedSlots: { customRender: 'dgrmResourceName' },
        },
        {
          title: '操作',
          width: '200px',
          dataIndex: 'action',
          scopedSlots: { customRender: 'action' },
        },
      ],
      // 加载数据方法 必须为 Promise 对象
      // loadData: parameter => {
      //   return getProfList(Object.assign(parameter, this.queryParam))
      // },
      previewVisible: false,
      previewImage: '',
      uploadUrl: process.env.VUE_APP_API_BASE_URL + deployByFileURL,
      headers: {
        'Authorization-Admin': Vue.ls.get(ACCESS_TOKEN),
      },
      // addEnable: checkPermission('activiti:prof:add'),
      // editEnabel: checkPermission('activiti:prof:edit'),
      // removeEnable: checkPermission('activiti:prof:remove')
      addEnable: true,
      editEnabel: true,
      removeEnable: true,
    }
  },
  computed: {
    rowSelection() {
      return {
        selectedRowKeys: this.selectedRowKeys,
        onChange: this.onSelectChange,
        getCheckboxProps: (record) => ({
          // props: {
          //   disabled: record.deploymentId, // Column configuration not to be checked
          //   name: record.name
          // }
        }),
      }
    },
  },
  filters: {},
  created() {
    this.getData()
  },
  methods: {

    reset(){
      this.queryParam = {}
      this.getData()
    },

    getData() {
      this.selectedRows = []
      this.selectedRowKeys = []
      this.tableLoading = true
      return getProfList({
        pageNumber: this.page.current,
        pageSize: this.page.pageSize,
        ...this.queryParam,
      })
        .then((res) => {
          if (res.code === 200) {
            const { total, records } = res.body
            this.page.total = total
            this.tableData = records
          }
        })
        .finally(() => (this.tableLoading = false))
    },
    // 隔行换色
    rowClassName(record, index) {
      let className = 'light-row'
      if (index % 2 === 1) className = 'dark-row'
      return className
    },
    onSelectChange(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys
    },

    /**
     * 更改分页
     */
    changeTable(pagination) {
      this.page = pagination
      this.getData()
    },

    handleNode(procDefId) {
      this.$refs.modal.isShow(procDefId)
      // this.$router.push({ name: 'procDefNode', query: { procDefId: procDefId } })
    },
    handleOk() {
      this.getData()
    },
    dataModal(dictType) {
      this.$refs.datamodal.show(dictType)
    },
    previewCancel() {
      this.previewVisible = false
    },
    handleShowImage(did) {
      diagram(did).then((res) => {
        const raw = res.data
        this.previewImage = URL.createObjectURL(raw)
      })
      this.previewVisible = true
    },
    uploadChange(info) {
      if (info.file.status !== 'uploading') {
        // console.log(info.file, info.fileList)
      }
      if (info.file.status === 'done') {
        const res = info.file.response
        if (res.code == 200) {
          this.handleOk()
          this.$message.success(res.message)
        } else {
          this.$message.error(res.message)
        }
      }
    },
    handleModelXml(record) {
      modelXml(record.deploymentId).then((res) => {
        const aLink = document.createElement('a')
        var blob = new Blob([res.data], { type: 'application/xml' })
        var fileName = record.resourceName
        aLink.href = URL.createObjectURL(blob)
        aLink.setAttribute('download', fileName) // 设置下载文件名称
        document.body.appendChild(aLink)
        aLink.click()
        document.body.removeChild(aLink)
        window.URL.revokeObjectURL(aLink.href)
      })
    },
    handleState(record) {
      const state = record.suspensionState === 1 ? 'suspend' : 'active'
      processState(record.id, state).then((res) => {
        if (res.code === 200) {
          record.suspensionState = record.suspensionState === 1 ? 2 : 1
        }
      })
    },
    delByIds(ids) {
      const _that = this
      _that.$confirm({
        title: '确认删除',
        content: '确认要删除选择的流程?',
        onOk() {
          delProf({ ids: ids.join(',') }).then((res) => {
            if (res.code === 200) {
              _that.$message.success(`删除成功`)
              _that.selectedRowKeys = []
            } else {
              _that.$message.error(res.message)
            }
            _that.handleOk()
          })
        },
        onCancel() {},
      })
    },
  },
  watch: {
    /*
      'selectedRows': function (selectedRows) {
        this.needTotalList = this.needTotalList.map(item => {
          return {
            ...item,
            total: selectedRows.reduce( (sum, val) => {
              return sum + val[item.dataIndex]
            }, 0)
          }
        })
      }
      */
  },
}
</script>

<style lang="less" scoped>
::v-deep .ant-table-tbody > .light-row {
  background: #fff !important;
}
::v-deep .ant-table-tbody > .dark-row {
  background: #fafafa !important;
}
</style>
